import React from 'react';

export default function LoginForm({ email, setEmail, password, setPassword, onSubmit }) {
    return (
        <div class='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
            <form class='space-y-6' onSubmit={onSubmit}>
                <div>
                    <label for='email' class='block text-sm font-medium leading-6 text-gray-900'>
                        Email address
                    </label>
                    <div class='mt-2'>
                        <input
                            id='email'
                            name='email'
                            type='email'
                            autocomplete='email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            class='block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                        />
                    </div>
                </div>

                <div>
                    <label for='password' class='block text-sm font-medium leading-6 text-gray-900'>
                        Password
                    </label>
                    <div class='mt-2'>
                        <input
                            id='password'
                            name='password'
                            type='password'
                            autocomplete='current-password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            class='block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                        />
                    </div>
                </div>

                <div>
                    <button
                        type='submit'
                        class='flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                    >
                        Sign in
                    </button>
                </div>
            </form>
        </div>
    );
}
