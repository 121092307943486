import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import configData from 'config/app.config.json';

import SidebarContainer from './SidebarContainer';
import Header from './Header';
import NavList from './NavList';
import NavItem from './NavItem';
import Logout from './Logout';

import { UserIcon } from 'components/common/Icon';

import { logout } from 'features/auth/authSlice';

import PhilSAIcon from 'assets/media/PhilSA_v2.png';

export default function Navbar() {
    const [showAvatarMenu, setShowAvatarMenu] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const nav = useSelector((state) => state.command.nav);

    const avatarMenuRef = useOutsideClick(() => {
        if (setShowAvatarMenu) setShowAvatarMenu(false);
    });

    const dispatch = useDispatch();

    const onLogout = (e) => {
        dispatch(logout());
    };
    return (
        <nav class='bg-primary w-full'>
            <div class='mx-auto px-4 sm:px-6 lg:px-8'>
                <div class='flex h-16 items-center justify-between'>
                    <BrandContainer>
                        <img class='h-8 w-8' src={PhilSAIcon} alt='PhilSA' />
                    </BrandContainer>
                    <FullMenuContainer>
                        {/* <NavLink to={'/'}>
                            <MenuItem selected={nav === ''} label={'Home'} />
                        </NavLink> */}
                        <NavLink to={'/command'}>
                            <MenuItem selected={nav === 'command'} label={'Command Generator'} />
                        </NavLink>
                    </FullMenuContainer>
                    <FullProfileContainer>
                        {/* <NotificationButton label={'View notifications'} /> */}
                        <AvatarContainer myRef={avatarMenuRef}>
                            <AvatarButton
                                onClick={() => {
                                    console.log('avatar button onclick');
                                    setShowAvatarMenu(true);
                                }}
                            ></AvatarButton>
                            <AvatarMenu
                                show={showAvatarMenu}
                                // onMouseLeave={() => setShowAvatarMenu(false)}
                                onClick={() => {
                                    console.log('avatar menu onclick');
                                    setShowAvatarMenu(false);
                                }}
                            >
                                <AvatarMenuItem label={'Your Profile'} />
                                <AvatarMenuItem label={'Settings'} />
                                <AvatarMenuItem label={'Logout'} onClick={onLogout} />
                            </AvatarMenu>
                        </AvatarContainer>
                    </FullProfileContainer>
                    <MobileMenuButton onClick={() => setShowMobileMenu(!showMobileMenu)} />
                </div>
                {/* Mobile Nav - will only show on mobile view */}
                <MobileMenu show={showMobileMenu}>
                    <div
                        class='space-y-1 px-2 pb-3 pt-2 sm:px-3'
                        onClick={() => setShowMobileMenu(false)}
                    >
                        <NavLink to={'/'}>
                            <MenuItem label={'Home'} />
                        </NavLink>
                        <NavLink to={'/command'}>
                            <MenuItem label={'Command Generator'} />
                        </NavLink>
                    </div>
                    <MobileAvatarContainer>
                        <MobileAvatarSegment>
                            <div class='flex-shrink-0'>
                                <AvatarButton></AvatarButton>
                            </div>
                        </MobileAvatarSegment>
                        <div class='mt-3 space-y-1 px-2'>
                            <AvatarMenuItem label={'Your Profile'} />
                            <AvatarMenuItem label={'Settings'} />
                            <AvatarMenuItem label={'Logout'} onClick={onLogout} />
                        </div>
                    </MobileAvatarContainer>
                </MobileMenu>
            </div>
        </nav>
    );
}

function MobileAvatarSegment({ children }) {
    return (
        <div class='flex items-center px-5'>
            {children}
            <div class='ml-3'>
                <div class='text-base font-medium leading-none text-white'>Placeholder User</div>
                <div class='text-sm font-medium leading-none text-gray-400'>user@example.com</div>
            </div>
            {/* <button
                type='button'
                class='ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'
            >
                <span class='sr-only'>View notifications</span>
                <svg
                    class='h-6 w-6'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke-width='1.5'
                    stroke='currentColor'
                    aria-hidden='true'
                >
                    <path
                        // stroke-linecap='round'
                        stroke-linejoin='round'
                        d='M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0'
                    />
                </svg>
            </button> */}
        </div>
    );
}

function MobileAvatarContainer({ children }) {
    return <div class='border-t border-gray-700 pb-3 pt-4'>{children}</div>;
}

function MobileMenu({ children, show, myRef, ...props }) {
    if (!show) return null;
    return (
        <div class='md:hidden' {...props}>
            {children}
        </div>
    );
}

function MobileMenuButton({ ...props }) {
    return (
        <div class='-mr-2 flex md:hidden' {...props}>
            <button
                type='button'
                class='inline-flex items-center justify-center rounded-md bg-secondary p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'
                aria-controls='mobile-menu'
                aria-expanded='false'
            >
                <span class='sr-only'>Open main menu</span>

                <svg
                    class='block h-6 w-6'
                    fill='none'
                    viewBox='0 0 24 24'
                    // stroke-width='1.5'
                    stroke='currentColor'
                    aria-hidden='true'
                >
                    <path
                        // stroke-linecap='round'
                        // stroke-linejoin='round'
                        d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5'
                    />
                </svg>

                <svg
                    class='hidden h-6 w-6'
                    fill='none'
                    viewBox='0 0 24 24'
                    // stroke-width='1.5'
                    stroke='currentColor'
                    aria-hidden='true'
                >
                    <path d='M6 18L18 6M6 6l12 12' />
                </svg>
            </button>
        </div>
    );
}

function NotificationButton({ label }) {
    return (
        <button
            type='button'
            class='rounded-full bg-secondary p-1 text-gray-400 hover:text-white hover:outline-none hover:ring-2 hover:ring-white hover:ring-offset-2 hover:ring-offset-gray-800'
        >
            <span class='sr-only'>{label}</span>
            <svg
                class='h-6 w-6'
                fill='none'
                viewBox='0 0 24 24'
                // stroke-width='1.5'
                stroke='currentColor'
                aria-hidden='true'
            >
                <path
                    // stroke-linecap='round'
                    // stroke-linejoin='round'
                    d='M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0'
                />
            </svg>
        </button>
    );
}

function AvatarMenu({ children, show, onClick, ...props }) {
    if (!show) return null;
    console.log('show');
    return (
        <div
            {...props}
            onClick={onClick}
            class='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
            role='menu'
            aria-orientation='vertical'
            aria-labelledby='user-menu-button'
            tabindex='-1'
        >
            {children}
        </div>
    );
}

function AvatarContainer({ children, myRef, ...props }) {
    return (
        <div ref={myRef} class='relative ml-3' {...props}>
            {children}
        </div>
    );
}

function AvatarMenuItem({ label, onClick }) {
    return (
        <div
            onClick={onClick}
            class='block rounded-md px-3 py-2 text-base font-medium text-gray-400 md:px-4 md:py-2 md:text-sm md:text-gray-700 hover:bg-secondary hover:text-white hover:round-md'
            role='menuitem'
            tabindex='-1'
            id='user-menu-item-0'
        >
            {label}
        </div>
    );
}

function AvatarButton({ ...props }) {
    return (
        <button
            {...props}
            type='button'
            class='flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'
        >
            <span class='sr-only'>Open user menu</span>
            <UserIcon className='text-primary h-8 w-8 rounded-full' />
            {/* <img
                class='h-8 w-8 rounded-full'
                src='https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
                alt=''
            /> */}
        </button>
    );
}

function FullProfileContainer({ children }) {
    return (
        <div class='hidden md:block'>
            <div class='ml-4 flex items-center md:ml-6 gap-5'>{children}</div>
        </div>
    );
}

function BrandContainer({ children }) {
    return (
        <div class='flex items-center'>
            <div class='flex-shrink-0'>{children}</div>
        </div>
    );
}

function FullMenuContainer({ children }) {
    return (
        <div class='hidden grow md:block'>
            <div class='ml-6 flex items-baseline'>{children}</div>
        </div>
    );
}

function MenuItem({ selected, label }) {
    return (
        <div
            class={`text-gray-300 block px-3 py-2 font-medium hover:bg-secondary hover:text-white rounded-md text-base md:text-sm hover:text-white ${
                selected ? 'underline underline-offset-2' : ''
            }`}
            aria-current='page'
        >
            {label}
        </div>
        // class='text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium'
    );
}

const useOutsideClick = (callback) => {
    const ref = useRef();

    useEffect(() => {
        const handleClick = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                // console.log('ref out');
                callback();
            }

            // console.log('ref click', ref);
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [ref]);

    return ref;
};

// <SidebarContainer>
//     <Header iconUrl={PhilSAIcon} appName={configData.appName} />
//     <NavList>
//         <NavItem label={'Home'} path={'/'} />
//         <NavItem label={'Command Generator'} path={'/command'} />
//     </NavList>
//     <Logout onLogout={onLogout} />
// </SidebarContainer>
