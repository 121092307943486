import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { createTask, togglePassListSelected } from 'features/command/commandSlice';

import { TableComponent, TableBody, TableHeader, TableRow } from 'components/common/TableComponent';
import { EyeIcon, EyeSlashIcon, AddIcon } from 'components/common/Icon';

import Moment from 'react-moment';
import 'moment-timezone';

import { LoadingOverlay } from '@mantine/core';

export default function PassListSection() {
    const dispatch = useDispatch();

    const createTaskLoading = useSelector((state) => state.command.createTaskLoading);
    const satellitePassList = useSelector((state) => state.command.satellitePassList);

    // if (loading) return <div>loading...</div>;

    const columns = [
        // 'Rise Time',
        'Center Time',
        'Look',
        'Direction',
        'Satellite',
        // 'Duration',
        'Capture Angle',
        'Action',
    ];
    const passData = satellitePassList.map((pass, i) => {
        const data = {
            satellite: pass.satellite_id,
            tle: pass.tle_id,
            path: pass.path_geojson.geometry,
            field_of_regard: pass.field_of_regard_geojson.geometry,
            center_time: pass.center_time,
            // sunshine_time: pass.sunshine_time,
            capture_angle: pass.capture_angle,
            look: pass.look,
            direction: pass.direction,
        };
        return [
            <Moment>{pass.center_time}</Moment>,
            pass.look,
            pass.direction,
            pass.satellite_name,
            // pass.duration,
            parseFloat(pass.capture_angle).toFixed(3),
            <div class='flex flex-row'>
                {pass.selected && (
                    <EyeIcon
                        className='h-8 w-8 p-1'
                        onClick={() => dispatch(togglePassListSelected({ index: i, value: false }))}
                    />
                )}
                {!pass.selected && (
                    <EyeSlashIcon
                        className='h-8 w-8 p-1'
                        onClick={() => dispatch(togglePassListSelected({ index: i, value: true }))}
                    />
                )}
                <AddIcon
                    className='h-8 w-8 p-1'
                    onClick={() => {
                        console.log(data);
                        dispatch(createTask({ data }));
                    }}
                />
            </div>,
        ];
    });

    // console.log(passData);

    return (
        <div>
            <LoadingOverlay visible={createTaskLoading} overlayBlur={2} zIndex={5} />
            <div class='text-left font-semibold text-lg'>Satellite Passes List</div>
            <TableComponent>
                <TableHeader columns={columns} />
                <TableBody>
                    {passData.map((pass, i) => (
                        <TableRow
                            key={`pass_${i}`}
                            // selected={
                            //     selectedSatellitePass ? i + 1 === selectedSatellitePass.id : false
                            // }
                        >
                            {pass.map((cell, c_i) => (
                                <td key={`cell_${c_i}`} class='px-2 text-sm'>
                                    {cell}
                                </td>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </TableComponent>
        </div>
    );
}
