import React from 'react';

export const TableComponent = ({ children }) => {
    return (
        <div class='grow relative overflow-x-auto'>
            <table class='w-full text-sm text-left text-gray-500 overflow-x-auto border-1 border-gray-100'>
                {children}
            </table>
        </div>
    );
};

export const TableHeader = ({ columns }) => {
    return (
        <thead class='text-xs text-gray-700 uppercase bg-gray-50'>
            <tr>
                {columns.map((label, i) => (
                    <th class='px-2 py-3'>{label}</th>
                ))}
            </tr>
        </thead>
    );
};

export const TableBody = ({ children }) => {
    return <tbody>{children}</tbody>;
};

export const TableRow = ({ children, selected }) => {
    return (
        <tr class={`bg-white border-b ${selected ? 'dark:bg-gray-300 dark:border-gray-200' : ''}`}>
            {children}
        </tr>
    );
};
