import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { setPayload } from 'features/command/commandSlice';

import { PillCheckbox } from 'components/common/Input';

import { Slider } from '@mantine/core';
import { Input } from '@mantine/core';

export default function PayloadOptions() {
    const [mfc, setMfc] = useState(false);
    const [wfc, setWfc] = useState(false);
    const [hpt, setHpt] = useState(false);
    const [hptnir, setHptnir] = useState(false);
    const [hptb, setHptb] = useState(false);
    const [hptg, setHptg] = useState(false);
    const [hptr, setHptr] = useState(false);
    const [smi, setSmi] = useState(false);
    const [sminir, setSminir] = useState(false);
    const [sminirfreq, setSminirfreq] = useState('740 753 778 865');
    const [smivisible, setSmivisible] = useState(false);
    const [smivisiblefreq, setSmivisiblefreq] = useState('490 550 620 670 708');
    const [erc, setErc] = useState(false);
    const [frameCount, setFrameCount] = useState(20);
    const [frameTime, setFrameTime] = useState(100);

    const dispatch = useDispatch();

    const taskList = useSelector((state) => state.command.taskList);
    const selectedTaskIndex = useSelector((state) => state.command.selectedTaskIndex);

    const selectedTask = taskList[selectedTaskIndex];

    useEffect(() => {
        // set initial values
        setMfc(selectedTask.mfc_on);
        setWfc(selectedTask.wfc_on);
        setHpt(selectedTask.hpt_on);
        setHptnir(selectedTask.hpt_n_on);
        setHptb(selectedTask.hpt_b_on);
        setHptg(selectedTask.hpt_g_on);
        setHptr(selectedTask.hpt_r_on);
        setSmi(selectedTask.smi_on);
        setSminir(selectedTask.smi_n_on);
        setSmivisible(selectedTask.smi_v_on);
        setSminirfreq(selectedTask.lctfn_frequency_string);
        setSmivisiblefreq(selectedTask.lctfv_frequency_string);
        setErc(selectedTask.erc_on);
        setFrameCount(selectedTask.frame_count);
        setFrameTime(selectedTask.frame_time);

        dispatch(
            setPayload({
                mfc_on: mfc,
                wfc_on: wfc,
                hpt_on: hpt,
                hpt_r_on: hptr,
                hpt_g_on: hptg,
                hpt_b_on: hptb,
                hpt_n_on: hptnir,
                smi_on: smi,
                smi_v_on: smivisible,
                smi_n_on: sminir,
                erc_on: erc,
                lctfv_frequency: smivisiblefreq,
                lctfn_frequency: sminirfreq,
                frame_count: parseInt(frameCount),
                frame_time: parseInt(frameTime),
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(
            setPayload({
                mfc_on: mfc,
                wfc_on: wfc,
                hpt_on: hpt,
                hpt_r_on: hptr,
                hpt_g_on: hptg,
                hpt_b_on: hptb,
                hpt_n_on: hptnir,
                smi_on: smi,
                smi_v_on: smivisible,
                smi_n_on: sminir,
                erc_on: erc,
                lctfv_frequency: smivisiblefreq,
                lctfn_frequency: sminirfreq,
                frame_count: parseInt(frameCount),
                frame_time: parseInt(frameTime),
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        mfc,
        wfc,
        hpt,
        hptr,
        hptg,
        hptb,
        hptnir,
        smi,
        smivisible,
        sminir,
        erc,
        smivisiblefreq,
        sminirfreq,
        frameCount,
        frameTime,
    ]);

    return (
        <div class='px-2'>
            <div class='px-4 sm:px-0'>
                <h2 class='text-base font-semibold text-gray-900'>Select Payload</h2>
            </div>
            <div class='mt-1 border-t border-gray-100'>
                <div class='flex flex-wrap'>
                    <div class='w-1/3'>
                        <PillCheckbox checked={mfc} onChange={(e) => setMfc(e.target.checked)}>
                            WFC
                        </PillCheckbox>
                        <PillCheckbox checked={wfc} onChange={(e) => setWfc(e.target.checked)}>
                            MFC
                        </PillCheckbox>
                        <PillCheckbox checked={erc} onChange={(e) => setErc(e.target.checked)}>
                            ERC
                        </PillCheckbox>
                    </div>
                    <div class='w-2/3 md:border-l border-gray-100'>
                        <PillCheckbox
                            checked={hpt}
                            onChange={(e) => {
                                setHpt(e.target.checked);
                                if (!e.target.checked) {
                                    setHptnir(false);
                                    setHptb(false);
                                    setHptg(false);
                                    setHptr(false);
                                }
                            }}
                        >
                            HPT
                        </PillCheckbox>
                        <div class='pl-4'>
                            <div class='flex flex-row'>
                                <div class=''>
                                    <PillCheckbox
                                        checked={hptnir}
                                        onChange={(e) => {
                                            setHptnir(e.target.checked);
                                            if (e.target.checked) setHpt(true);
                                        }}
                                    >
                                        NIR
                                    </PillCheckbox>
                                    <PillCheckbox
                                        checked={hptb}
                                        onChange={(e) => {
                                            setHptb(e.target.checked);
                                            if (e.target.checked) setHpt(true);
                                        }}
                                    >
                                        Blue
                                    </PillCheckbox>
                                </div>
                                <div class=''>
                                    <PillCheckbox
                                        checked={hptg}
                                        onChange={(e) => {
                                            setHptg(e.target.checked);
                                            if (e.target.checked) setHpt(true);
                                        }}
                                    >
                                        Green
                                    </PillCheckbox>
                                    <PillCheckbox
                                        checked={hptr}
                                        onChange={(e) => {
                                            setHptr(e.target.checked);
                                            if (e.target.checked) setHpt(true);
                                        }}
                                    >
                                        Red
                                    </PillCheckbox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='w-full border-t border-gray-100'>
                        <PillCheckbox
                            checked={smi}
                            onChange={(e) => {
                                setSmi(e.target.checked);
                                if (!e.target.checked) {
                                    setSmivisible(false);
                                    setSminir(false);
                                }
                            }}
                        >
                            SMI
                        </PillCheckbox>
                        <div class='pl-4'>
                            <div class='flex flex-row'>
                                <div>
                                    <PillCheckbox
                                        checked={smivisible}
                                        onChange={(e) => {
                                            setSmivisible(e.target.checked);
                                            if (e.target.checked) setSmi(true);
                                        }}
                                    >
                                        Visible
                                    </PillCheckbox>
                                    {/* <Textbox
                                        label={'Filter Frequencies'}
                                        value={smivisiblefreq}
                                        onChange={(e) => setSmivisiblefreq(e.target.value)}
                                    /> */}
                                    <Input.Wrapper
                                        label='Filter Frequencies'
                                        description='Space separated Frequencies'
                                    >
                                        <Input
                                            disabled={!smivisible}
                                            value={smivisiblefreq}
                                            onChange={(e) => setSmivisiblefreq(e.target.value)}
                                        />
                                    </Input.Wrapper>
                                </div>
                                <div>
                                    <PillCheckbox
                                        checked={sminir}
                                        onChange={(e) => {
                                            setSminir(e.target.checked);
                                            if (e.target.checked) setSmi(true);
                                        }}
                                    >
                                        NIR
                                    </PillCheckbox>
                                    {/* <Textbox
                                        label={'Filter Frequencies'}
                                        value={sminirfreq}
                                        onChange={(e) => setSminirfreq(e.target.value)}
                                    /> */}
                                    <Input.Wrapper
                                        label='Filter Frequencies'
                                        description='Space separated Frequencies'
                                    >
                                        <Input
                                            disabled={!sminir}
                                            value={sminirfreq}
                                            onChange={(e) => setSminirfreq(e.target.value)}
                                        />
                                    </Input.Wrapper>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='w-full'>
                        <div>
                            <div>Frame Count: {frameCount} frames</div>
                            <Slider
                                value={frameCount}
                                min={5}
                                max={50}
                                thumbSize={22}
                                onChange={(value) => setFrameCount(value)}
                            ></Slider>
                        </div>
                        <div>
                            <div>Frame Time: {frameTime} ms</div>
                            <Slider
                                value={frameTime}
                                min={50}
                                max={400}
                                thumbSize={22}
                                onChange={(value) => setFrameTime(value)}
                            />
                        </div>
                        <div>
                            Total Capture Duration: {((frameCount * frameTime) / 1000).toFixed(3)}{' '}
                            seconds
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
