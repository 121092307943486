import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { setPassNav } from 'features/command/commandSlice';

import PassListSection from 'components/command/TopLeftCompartment/PassListSection';

import PassParameterSection from 'components/command/TopLeftCompartment/PassParameterSection';

import { Nav, NavItem } from 'components/common/TabNav';

export default function TopLeftCompartment() {
    const dispatch = useDispatch();

    const passNav = useSelector((state) => state.command.passNav);

    return (
        <div class='relative overflow-auto'>
            <Nav>
                <NavItem
                    selected={passNav === 'pass-parameter'}
                    onClick={() => dispatch(setPassNav('pass-parameter'))}
                >
                    Pass Parameter
                </NavItem>
                <NavItem
                    selected={passNav === 'pass-list'}
                    onClick={() => {
                        if (true) dispatch(setPassNav('pass-list'));
                    }}
                >
                    Pass List
                </NavItem>
            </Nav>
            <div>
                {passNav === 'pass-parameter' && <PassParameterSection />}
                {passNav === 'pass-list' && <PassListSection />}
            </div>
        </div>
    );
}
