import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faTwitter,
    faFacebook,
    faPinterest,
    faGithub,
    faWhatsapp,
    faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import {
    faClipboard,
    faEye,
    faEyeSlash,
    faDownload,
    faUserAstronaut,
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
library.add(faEye, faEyeSlash, faClipboard, faDownload, faUserAstronaut, faPlus);

export const EyeIcon = ({ className, ...props }) => {
    return <FontAwesomeIcon className={className} icon='fa-solid fa-eye' {...props} />;
};

export const EyeSlashIcon = ({ className, ...props }) => {
    return <FontAwesomeIcon className={className} icon='fa-solid fa-eye-slash' {...props} />;
};

export const DetailIcon = ({ className, ...props }) => {
    return <FontAwesomeIcon className={className} icon='fa-solid fa-clipboard' {...props} />;
};

export const DownloadIcon = ({ className, ...props }) => {
    return <FontAwesomeIcon className={className} icon='fa-solid fa-download' {...props} />;
};

export const UserIcon = ({ className, ...props }) => {
    return <FontAwesomeIcon className={className} icon='fa-solid fa-user-astronaut' {...props} />;
};

export const AddIcon = ({ className, ...props }) => {
    return <FontAwesomeIcon className={className} icon='fa-solid fa-plus' {...props} />;
};
