import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

import LoginContainer from 'components/login/LoginContainer';
import Header from 'components/login/Header';
import LoginForm from 'components/login/LoginForm';

import { login } from 'features/auth/authSlice';

import PhilSABanner from 'assets/media/PhilSA_v4-01.png';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const dispatch = useDispatch();

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    if (isAuthenticated) return <Navigate to={'/'} />;
    // return redirect('/home/');

    const onSubmit = (e) => {
        e.preventDefault();
        console.log('submit');
        console.log({ email, password });
        dispatch(login({ email, password }));
    };

    return (
        <LoginContainer>
            <Header iconUrl={PhilSABanner} />
            <LoginForm
                email={email}
                password={password}
                setEmail={setEmail}
                setPassword={setPassword}
                onSubmit={onSubmit}
            />
        </LoginContainer>
    );
}
