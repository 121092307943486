import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
    getSatellites,
    getSatellitePasses,
    setSatellite,
    enablePointSelect,
    setStartDate,
    setEndDate,
    setFieldOfRegard,
} from 'features/command/commandSlice';

import { DatePickerInput } from '@mantine/dates';
import dayjs from 'dayjs';

import { Select } from '@mantine/core';
import { Input } from '@mantine/core';

import { LoadingOverlay } from '@mantine/core';

import { SuccessButton } from 'components/common/Button';

import { isValidFloat } from 'utils/utils';

import { notifications } from '@mantine/notifications';

export default function PassParameter() {
    const dispatch = useDispatch();

    const hasSatellite = useSelector((state) => state.command.hasSatellite);
    const selectedSatellite = useSelector((state) => state.command.selectedSatellite);
    const satelliteList = useSelector((state) => state.command.satelliteList);
    const getSatellitePassListLoading = useSelector(
        (state) => state.command.getSatellitePassListLoading,
    );
    const hasTarget = useSelector((state) => state.command.hasTarget);
    const target = useSelector((state) => state.command.target);
    const startDate = useSelector((state) => state.command.startDate);
    const endDate = useSelector((state) => state.command.endDate);
    const fieldOfRegard = useSelector((state) => state.command.fieldOfRegard);

    useEffect(() => {
        // dispatch(setStep('select-satellite'));
        if (!hasSatellite) {
            dispatch(getSatellites());
        }
    }, []);

    if (!hasSatellite) {
        return null;
    }

    const onSubmit = () => {
        let hasError = false;
        // error validation
        if (!hasTarget) {
            notifications.show({
                title: 'Warning!',
                message: 'Please select a target.',
                color: 'orange',
            });
            hasError = true;
        }
        if (!startDate || !endDate) {
            notifications.show({
                title: 'Warning!',
                message: 'Please provide the date range.',
                color: 'orange',
            });
            hasError = true;
        }
        if (!isValidFloat(fieldOfRegard)) {
            notifications.show({
                title: 'Warning!',
                message: 'Please provide a valid field of regard',
                color: 'orange',
            });
            hasError = true;
        }

        if (hasError) return;

        const data = {
            target_lat: target.lat,
            target_lon: target.lon,
            start_date: startDate,
            end_date: endDate,
            field_of_regard: parseFloat(fieldOfRegard),
        };

        dispatch(
            getSatellitePasses({
                satelliteId: selectedSatellite.id,
                data,
            }),
        );
    };

    return (
        <div class='bg-white p-2'>
            <LoadingOverlay visible={getSatellitePassListLoading} overlayBlur={2} zIndex={5} />
            <div>
                <div class='mt-2'>
                    <div class='text-center font-semibold text-lg'>Satellite Parameters</div>
                    <FormItem label={'Satellite'}>
                        <div class='w-full md:w-1/2'>
                            <Select
                                data={satelliteList.map((satellite) => {
                                    return { value: satellite, label: satellite.name };
                                })}
                                value={selectedSatellite}
                                onChange={(value) => dispatch(setSatellite(value))}
                            />
                        </div>
                    </FormItem>
                    <FormItem label={'Target'}>
                        <div class='flex flex-wrap'>
                            <div class='w-full md:w-1/2 lg:w-1/3'>
                                <label class='block text-xs font-medium leading-6 text-gray-900'>
                                    Latitude
                                </label>
                                <div class='mt-0 mr-5'>
                                    <div
                                        disabled
                                        value={hasTarget ? target.lon : ''}
                                        type='text'
                                        class='pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 '
                                    >
                                        {hasTarget ? target.lat : ''}
                                    </div>
                                </div>
                            </div>
                            <div class='w-full md:w-1/2 lg:w-1/3'>
                                <label class='block text-xs font-medium leading-6 text-gray-900'>
                                    Longitude
                                </label>
                                <div class='mt-0 mr-5'>
                                    <div
                                        disabled
                                        value={hasTarget ? target.lon : ''}
                                        type='text'
                                        class='pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 '
                                    >
                                        {hasTarget ? target.lon : ''}
                                    </div>
                                </div>
                            </div>
                            <div class='w-full grid content-end lg:w-1/3'>
                                <div class='w-full'>
                                    <SuccessButton
                                        onClick={() => {
                                            dispatch(enablePointSelect());
                                        }}
                                    >
                                        Get Target
                                    </SuccessButton>
                                </div>
                            </div>
                        </div>
                    </FormItem>
                    <FormItem label={'Date Range'}>
                        <div class='w-full md:w-3/4'>
                            <DatePickerInput
                                type='range'
                                valueFormat='MMMM DD, YYYY HH:ss:ss Z'
                                allowSingleDateInRange
                                onChange={(dates) => {
                                    const startTemp = dates[0];
                                    const endTemp = dates[1];
                                    if (startTemp) {
                                        startTemp.setHours(0);
                                        startTemp.setMinutes(0);
                                        startTemp.setSeconds(0);
                                    }
                                    if (endTemp) {
                                        endTemp.setHours(23);
                                        endTemp.setMinutes(59);
                                        endTemp.setSeconds(59);
                                    }
                                    dispatch(
                                        setStartDate(startTemp ? startTemp.toISOString() : null),
                                    );
                                    dispatch(setEndDate(endTemp ? endTemp.toISOString() : null));
                                    console.log(dates);
                                }}
                                value={[
                                    startDate ? dayjs(startDate) : null,
                                    endDate ? dayjs(endDate) : null,
                                ]}
                            />
                        </div>
                    </FormItem>
                    <FormItem label={'Field of Regard'}>
                        <div class='w-full md:w-1/2'>
                            <Input.Wrapper
                                error={
                                    isValidFloat(fieldOfRegard) || fieldOfRegard === ''
                                        ? ''
                                        : 'Please provide a valid value.'
                                }
                            >
                                <Input
                                    value={fieldOfRegard}
                                    onChange={(e) => dispatch(setFieldOfRegard(e.target.value))}
                                />
                            </Input.Wrapper>
                        </div>
                    </FormItem>
                    <div class='mt-8 flex justify-center'>
                        <div class='w-full lg:w-2/3'>
                            <SuccessButton onClick={onSubmit}>Get Passes</SuccessButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function FormItem({ label, children }) {
    return (
        <div class='my-2'>
            <label class='block text-md font-medium leading-6 text-gray-900 pb-1'>{label}</label>
            {children}
        </div>
    );
}
