import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Navbar from 'components/navbar/Navbar';
import CommandGenerator from 'pages/CommandGenerator';
import Footer from 'components/common/Footer';

export default function AuthenticatedComponent() {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    if (isAuthenticated !== true) return <Navigate to={'/login'} />;

    return (
        <div class='min-h-screen'>
            <div class='flex flex-col'>
                <div class='flex-none sticky top-0 z-50'>
                    <Navbar />
                </div>
                <div class='grow'>
                    <Routes>
                        {/* <Route path={'/'} element={<div class='h-160'>Home</div>} /> */}
                        <Route path={'/command/*'} element={<CommandGenerator />} />
                        <Route path={'/*'} element={<Navigate to={'/command'} />} />
                    </Routes>
                </div>
                <Footer />
            </div>
        </div>
    );
}
