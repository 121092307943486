import axios from 'axios';

const api = axios.create({
    timeout: 1000 * 3600,
});

// http request for sat available
export const fetchSatellites = () => {
    const url = `${process.env.REACT_APP_API_URL}/orbit/satellite/`;
    const headers = {
        Authorization: `Token ${localStorage.getItem('token')}`,
    };

    const req = api({
        method: 'get',
        url,
        headers,
    });
    return req;
};

export const fetchSatellitePasses = ({ satelliteId, data }) => {
    const url = `${process.env.REACT_APP_API_URL}/orbit/satellite/${satelliteId}/passes/`;
    const headers = {
        Authorization: `Token ${localStorage.getItem('token')}`,
    };

    console.log({ satelliteId, data });
    const req = api({
        method: 'post',
        url,
        data: data,
        headers,
    });
    console.log(req);
    return req;
};

export const fetchTasks = () => {
    const url = `${process.env.REACT_APP_API_URL}/orbit/task/`;
    const headers = {
        Authorization: `Token ${localStorage.getItem('token')}`,
    };

    const req = api({
        method: 'get',
        url,
        headers,
    });
    console.log(req);
    return req;
};

export const fetchCaptureArea = ({ satelliteId, data }) => {
    const url = `${process.env.REACT_APP_API_URL}/orbit/satellite/${satelliteId}/capture-area/`;
    const headers = {
        Authorization: `Token ${localStorage.getItem('token')}`,
    };

    console.log({ satelliteId, data });
    const req = api({
        method: 'post',
        url,
        data: data,
        headers,
    });
    // console.log(req);
    return req;
};

export const fetchCommand = ({ satelliteId, data }) => {
    const url = `${process.env.REACT_APP_API_URL}/orbit/satellite/${satelliteId}/generate_command/`;
    const headers = {
        Authorization: `Token ${localStorage.getItem('token')}`,
    };

    console.log({ satelliteId, data });
    const req = api({
        method: 'post',
        url,
        data: data,
        headers,
    });
    // console.log(req);
    return req;
};

export const postPass = ({ data }) => {
    const url = `${process.env.REACT_APP_API_URL}/orbit/task/`;
    const headers = {
        Authorization: `Token ${localStorage.getItem('token')}`,
    };

    const req = api({
        method: 'post',
        url,
        data: data,
        headers,
    });

    return req;
};

export const postCaptureInfo = ({ taskId, data }) => {
    const url = `${process.env.REACT_APP_API_URL}/orbit/task/${taskId}/opportunity-info/`;
    const headers = {
        Authorization: `Token ${localStorage.getItem('token')}`,
    };

    const req = api({
        method: 'post',
        url,
        data: data,
        headers,
    });

    return req;
};
