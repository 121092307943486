import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { setTaskNav } from 'features/command/commandSlice';

import TaskDetailSection from 'components/command/BottomCompartment/TaskDetailSection/TaskDetailSection';
import TaskListSection from 'components/command/BottomCompartment/TaskListSection';

import { Nav, NavItem } from 'components/common/TabNav';

export default function BottomCompartment() {
    const dispatch = useDispatch();

    const taskNav = useSelector((state) => state.command.taskNav);

    return (
        <div class='relative overflow-auto'>
            <Nav>
                <NavItem
                    selected={taskNav === 'task-list'}
                    onClick={() => dispatch(setTaskNav('task-list'))}
                >
                    Task List
                </NavItem>
                <NavItem
                    selected={taskNav === 'task-detail'}
                    // onClick={() => dispatch(setTaskNav('task-detail'))}
                >
                    Task Details
                </NavItem>
            </Nav>
            <div>
                {taskNav === 'task-list' && <TaskListSection />}
                {taskNav === 'task-detail' && <TaskDetailSection />}
            </div>
        </div>
    );
}
