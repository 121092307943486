import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
    getTasks,
    toggleTaskListSelected,
    setSelectedTaskIndex,
    setTaskNav,
} from 'features/command/commandSlice';

import { TableComponent, TableBody, TableHeader, TableRow } from 'components/common/TableComponent';
import { EyeIcon, EyeSlashIcon, DetailIcon } from 'components/common/Icon';

import Moment from 'react-moment';
import 'moment-timezone';

export default function TaskListSection() {
    const dispatch = useDispatch();

    const loading = useSelector((state) => state.command.loading);
    const hasTaskList = useSelector((state) => state.command.hasTaskList);
    const taskList = useSelector((state) => state.command.taskList);

    useEffect(() => {
        if (!hasTaskList) {
            dispatch(getTasks());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) return <div>loading...</div>;

    const columns = [
        // 'Selected',
        'Caption',
        'Satellite',
        'Status',
        'Center Time',
        'Look',
        'Direction',
        'Action',
    ];
    const taskListData = taskList.map((task, i) => {
        return [
            task.caption,
            task.satellite_name,
            task.status,
            <Moment>{task.center_time}</Moment>,
            task.look,
            task.direction,
            <div class='flex flex-row'>
                {task.selected && (
                    <EyeIcon
                        className='h-8 w-8 p-1'
                        onClick={() => dispatch(toggleTaskListSelected({ index: i, value: false }))}
                    />
                )}
                {!task.selected && (
                    <EyeSlashIcon
                        className='h-8 w-8 p-1'
                        onClick={() => dispatch(toggleTaskListSelected({ index: i, value: true }))}
                    />
                )}

                <DetailIcon
                    className='h-8 w-8 p-1'
                    onClick={() => {
                        dispatch(setTaskNav('task-detail'));
                        dispatch(setSelectedTaskIndex(i));
                    }}
                />
            </div>,
        ];
    });

    return (
        <div>
            <div class='text-left font-semibold text-lg'>Task List</div>
            <TableComponent>
                <TableHeader columns={columns} />
                <TableBody>
                    {taskListData.map((task, i) => (
                        <TableRow hey={`pass_${i}`}>
                            {task.map((cell, c_i) => (
                                <td key={`cell_${c_i}`} class='px-2 text-sm'>
                                    {cell}
                                </td>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </TableComponent>
        </div>
    );
}
