export const isValidFloat = (value) => {
    // Use a regular expression or any other validation logic
    // that suits your requirements
    const floatRegex = /^-?\d+(\.\d+)?$/;
    return floatRegex.test(value);
};

export const isValidInteger = (value) => {
    const integerRegex = /^-?\d+$/;
    return integerRegex.test(value);
};

export const isListAllInteger = (list) => {
    for (let i = 0; i < list.length; i++) {
        const num = Number(list[i]);
        if (!Number.isInteger(num)) {
            return false;
        }
    }
    return true;
};
