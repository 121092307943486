import React from 'react';

export const PillCheckbox = ({ children, ...props }) => {
    return (
        <div class='flex p-1 rounded'>
            <label class='relative inline-flex items-center w-full cursor-pointer'>
                <input type='checkbox' value='' class='sr-only peer' {...props} />
                <div class="w-9 h-5 bg-gray-200 rounded-full peer dark:bg-gray-600 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-500 peer-checked:bg-success"></div>
                <span class='ml-3 text-sm font-medium'>{children}</span>
            </label>
        </div>
    );
};
