import React from 'react';

import CommandMap from 'components/command/CommandMap';
import BottomCompartment from 'components/command/BottomCompartment/BottomCompartment';
import TopLeftCompartment from 'components/command/TopLeftCompartment/TopLeftCompartment';

export default function CommandGenerator() {
    return (
        <div class='flex flex-col h-full w-full overflow-auto'>
            <div class='text-center font-bold text-4xl pt-10 pb-10 bg-green-100'>
                Command Generator
            </div>
            <div class='flex flex-wrap-reverse px-4 lg:px-16 pt-4'>
                <div class='w-full h-128 md:w-1/2 px-5 overflow-auto'>
                    <TopLeftCompartment />
                </div>
                <div class='flex-none h-128 w-full z-10 p-2 md:w-1/2'>
                    <CommandMap></CommandMap>
                </div>
            </div>
            <div class='grow h-full'>
                <div class='flex flex-col grow max-h-160 px-4 lg:px-16 py-4'>
                    <BottomCompartment />
                </div>
            </div>
            <div class='h-20'></div>
        </div>
    );
}
