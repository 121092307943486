import React from 'react';

export const Nav = ({ children }) => {
    return (
        <ul class='sticky top-0 z-10 bg-white flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-300'>
            {children}
        </ul>
    );
};

export const NavItem = ({ children, selected, ...props }) => {
    return (
        <li class='mr-2' {...props}>
            <div
                class={`select-none inline-block w-32 px-3 py-2 text-success bg-gray-100 rounded-t-lg hover:bg-success hover:text-white ${
                    selected ? 'bg-success text-white dark:text-white' : ''
                }`}
            >
                {children}
            </div>
        </li>
    );
};
