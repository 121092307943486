import axios from 'axios';

const api = axios.create({
    timeout: 1000 * 3600,
});

// http request for sat available
export const loginAPI = ({ email, password }) => {
    const url = `${process.env.REACT_APP_API_AUTH_URL}/login/`;
    console.log(url);
    return api({
        method: 'post',
        url,
        data: {
            email,
            password,
        },
    });
};

// http request for sat available
export const logoutAPI = () => {
    const url = `${process.env.REACT_APP_API_AUTH_URL}/logout/`;
    const headers = {
        Authorization: `Token ${localStorage.getItem('token')}`,
        // 'X-CSRFToken': Cookies.get('csrftoken'),
    };

    const req = api({
        method: 'post',
        url,
        headers,
    });
    console.log(req);
    return req;
};
