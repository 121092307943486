import React from 'react';

export default function Footer() {
    return (
        <footer>
            <>
                <div className='bg-[#1F1F1F] '>
                    <div className='max-w-5xl mx-auto text-white py-16 px-4'>
                        <div className='font-poppins font-bold text-3xl '> Contact Us</div>
                        <div className='grid grid-cols-2 font-ptSans md:gap-6 gap-2 mt-9  '>
                            <div>Email: info@philsa.gov.ph</div>
                            <div>Quick Links</div>
                            <div>
                                Address: 29th Floor, Cyber One Building, 11 Eastwood Ave. Bagumbayan
                                Quezon City
                            </div>
                            <div>
                                <a
                                    className='no-underline text-white'
                                    href='https://philsa.gov.ph/about/'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    About PhilSA
                                </a>
                            </div>
                            <div>Phone: +632 8568 99 31</div>
                        </div>
                    </div>
                </div>
                <div className='bg-black text-white text-center font-poppins py-7'>
                    Copyright Philippine Space Agency 2023
                </div>
            </>
        </footer>
    );
}
