import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';

import './App.css';

import AuthenticatedComponent from 'pages/AuthenticatedComponent';
import Login from 'pages/Login';
import Map from 'pages/Map';

function App() {
    return (
        <MantineProvider>
            <Notifications position='top-right' zIndex={2000} autoClose={1500} />
            <BrowserRouter>
                <Routes>
                    <Route path={`/login`} element={<Login />} />
                    <Route path={`/map`} element={<Map />} />
                    <Route path={'/*'} element={<AuthenticatedComponent />} />
                </Routes>
            </BrowserRouter>
        </MantineProvider>
    );
}

export default App;
