import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { downloadCsvCommand } from 'features/command/commandSlice';

import { TableComponent, TableBody, TableHeader, TableRow } from 'components/common/TableComponent';
import { DownloadIcon } from 'components/common/Icon';

export default function CommandSection() {
    const dispatch = useDispatch();

    const loading = useSelector((state) => state.command.loading);
    const taskList = useSelector((state) => state.command.taskList);
    const selectedTaskIndex = useSelector((state) => state.command.selectedTaskIndex);

    const selectedTask = taskList[selectedTaskIndex];

    if (loading) return <div>Loading...</div>;

    // if (!selectedTask.has_command) {
    //     return <div>No Commands</div>;
    // }

    const columns = ['Command', 'PhST', 'Wait', 'UTC', 'JST'];

    const commandList = selectedTask.has_command ? JSON.parse(selectedTask.command) : [];

    const commandData = commandList.map((command, i) => {
        return [command.command, command.phst, command.wait, command.utc, command.jst];
    });

    return (
        <div class='px-2 h-96 overflow-auto'>
            <div class='sticky top-0 z-10 bg-white px-4 sm:px-0'>
                <h2 class='text-base font-semibold text-gray-900'>Command Table</h2>
            </div>
            <div class='mt-1 border-t border-gray-100'>
                {!selectedTask.has_command && <div>No Command.</div>}
                {selectedTask.has_command && (
                    <>
                        <div onClick={() => dispatch(downloadCsvCommand())}>
                            <DownloadIcon></DownloadIcon>
                            <span class='pl-2 text-xs select-none'>Download as CSV File</span>
                        </div>
                        <TableComponent>
                            <TableHeader columns={columns} />
                            <TableBody>
                                {commandData.map((command, i) => (
                                    <TableRow key={`command_${i}`} selected={false}>
                                        {command.map((cell, c_i) => (
                                            <td key={`cell_${c_i}`} class='px-2 text-2xs'>
                                                {cell}
                                            </td>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </TableComponent>
                    </>
                )}
            </div>
        </div>
    );
}
