import React, { Fragment, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setTarget } from 'features/command/commandSlice';

import { MapContainer, TileLayer, Marker, useMapEvents, GeoJSON } from 'react-leaflet';
import L from 'leaflet';

export default function CommandMap() {
    const mapRef = useRef();

    const dispatch = useDispatch();

    const selectingTarget = useSelector((state) => state.command.selectingTarget);
    const hasTarget = useSelector((state) => state.command.hasTarget);
    const target = useSelector((state) => state.command.target);
    const hasSatellitePassList = useSelector((state) => state.command.hasSatellitePassList);
    const satellitePassList = useSelector((state) => state.command.satellitePassList);
    const hasTaskList = useSelector((state) => state.command.hasTaskList);
    const taskList = useSelector((state) => state.command.taskList);

    useEffect(() => {
        const map = mapRef.current;

        if (!map) return undefined;

        const handleCursorChange = (event) => {
            if (selectingTarget) {
                L.DomEvent.preventDefault(event);
                // eslint-disable-next-line no-underscore-dangle
                event.target._container.style.cursor = 'crosshair';
            } else {
                // eslint-disable-next-line no-underscore-dangle
                event.target._container.style.cursor = 'default';
            }
        };

        L.DomEvent.on(map, 'mousemove', handleCursorChange);

        return () => {
            L.DomEvent.off(map, 'mousemove', handleCursorChange);
        };
    }, [selectingTarget]);

    function MapClick() {
        useMapEvents({
            click: (e) => {
                // console.log(event.latlng);
                if (selectingTarget) {
                    dispatch(setTarget({ lat: e.latlng.lat, lon: e.latlng.lng }));
                }
            },
        });
        return null;
    }
    return (
        <MapContainer
            ref={mapRef}
            className='w-full h-full overflow-hidden'
            // viewport={viewport}
            center={[14, 121]}
            zoom={5}
            maxZoom={20}
            minZoom={3}
            zoomControl={false}
            preferCanvas
            worldCopyJump
        >
            {/* Openstreatmaps Carto Map */}
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                url='https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png'
            />
            <MapClick />
            {hasTarget && <Marker position={[target.lat, target.lon]} />}
            {hasSatellitePassList &&
                satellitePassList
                    .filter((pass) => pass.selected)
                    .map((pass, i) => (
                        <>
                            <GeoJSON
                                key={`path_${pass.id}`}
                                style={() => {
                                    return {
                                        weight: 1,
                                        // color: '#36bfb6'
                                    };
                                }}
                                data={pass.path_geojson}
                            />
                            <GeoJSON
                                key={`for_${pass.id}`}
                                style={() => {
                                    return {
                                        weight: 2,
                                        color: '#dba81a',
                                    };
                                }}
                                data={pass.field_of_regard_geojson}
                            />
                        </>
                    ))}
            {hasTaskList &&
                taskList
                    .filter((t) => t.selected)
                    .map((task, i) => (
                        <Fragment
                            key={`task_geojson_${task.center_time}_${task.id}_${task.center_time}_${task.capture_angle}_${task.half_angle}_${task.padding}`}
                        >
                            <GeoJSON
                                key={`ca_path_${task.id}`}
                                style={() => {
                                    return {
                                        weight: 1,
                                        // color: '#36bfb6'
                                    };
                                }}
                                data={task.path}
                            />
                            <GeoJSON
                                key={`ca_for_${task.center_time}_${task.id}_${task.center_time}_${task.capture_angle}_${task.half_angle}_${task.padding}`}
                                style={() => {
                                    return {
                                        weight: 2,
                                        color: '#dba81a',
                                    };
                                }}
                                data={task.field_of_regard}
                            />
                            <GeoJSON
                                key={`ca_${task.center_time}_${task.id}_${task.center_time}_${task.capture_angle}_${task.half_angle}_${task.padding}`}
                                style={() => {
                                    return {
                                        weight: 2,
                                        color: '#fa193b',
                                    };
                                }}
                                data={task.capture_area}
                            />
                        </Fragment>
                    ))}
            {selectingTarget && (
                <div class='flex w-full absolute top-3/4 left-0 z-[1000] text-center text-4xl text-red-500 select-none font-semibold place-content-center'>
                    Select a Point on the map
                </div>
            )}
        </MapContainer>
    );
}
