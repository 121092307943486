import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { setCaptureInfo } from 'features/command/commandSlice';

import { Input } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';

export default function CaptureOpportunityInfo() {
    const dispatch = useDispatch();

    const [caption, setCaption] = useState('');
    const [centerTime, setCenterTime] = useState(new Date());
    const [captureAngle, setCaptureAngle] = useState(0);
    const [halfAngle, setHalfAngle] = useState(0);
    const [padding, setPadding] = useState(0);
    const [roll, setRoll] = useState(0);
    const [pitch, setPitch] = useState(0);
    const [yaw, setYaw] = useState(0);

    const taskList = useSelector((state) => state.command.taskList);
    const selectedTaskIndex = useSelector((state) => state.command.selectedTaskIndex);

    const selectedTask = taskList[selectedTaskIndex];

    useEffect(() => {
        setCaption(selectedTask['caption']);
        setCenterTime(new Date(selectedTask['center_time']));
        setCaptureAngle(selectedTask['capture_angle']);
        setHalfAngle(selectedTask['half_angle']);
        setPadding(selectedTask['padding']);
        setRoll(selectedTask['roll']);
        setPitch(selectedTask['pitch']);
        setYaw(selectedTask['yaw']);

        dispatch(
            setCaptureInfo({
                caption: caption,
                center_time: centerTime,
                capture_angle: captureAngle,
                half_angle: halfAngle,
                padding: padding,
                roll: roll,
                pitch: pitch,
                yaw: yaw,
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(
            setCaptureInfo({
                caption: caption,
                center_time: centerTime,
                capture_angle: captureAngle,
                half_angle: halfAngle,
                padding: padding,
                roll: roll,
                pitch: pitch,
                yaw: yaw,
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [caption, centerTime, captureAngle, halfAngle, padding, roll, pitch, yaw]);

    return (
        <div class='px-2'>
            <div class='px-4 sm:px-0'>
                <h2 class='text-base font-semibold text-gray-900'>Capture Opportunity Info</h2>
            </div>
            <div class='mt-1 border-t border-gray-100'>
                <InfoItem label={'Caption'}>
                    <Input value={caption} onChange={(e) => setCaption(e.target.value)} size='xs' />
                </InfoItem>
                <InfoItem label={'Satellite'}>{selectedTask.satellite_name}</InfoItem>
                <InfoItem label={'Status'}>{selectedTask.status}</InfoItem>
                <InfoItem label={'Center Time'}>
                    <DateTimePicker
                        withSeconds
                        value={centerTime}
                        valueFormat='MM/DD/YYYY HH:mm:ss Z'
                        onChange={(date) => setCenterTime(date)}
                    />
                </InfoItem>
                <InfoItem label={'Sunshine Time'}>
                    <DateTimePicker
                        disabled
                        withSeconds
                        value={new Date(selectedTask.sunshine_time)}
                        valueFormat='MM/DD/YYYY HH:mm:ss Z'
                    />
                </InfoItem>
                <InfoItem label={'Look'}>{selectedTask.look}</InfoItem>
                <InfoItem label={'Direction'}>{selectedTask.direction}</InfoItem>
                <InfoItem label={'Angle'}>
                    <Input
                        value={captureAngle}
                        onChange={(e) => setCaptureAngle(e.target.value)}
                        size='xs'
                    />
                </InfoItem>
                <InfoItem label={'Capture Padding'}>
                    <Input value={padding} onChange={(e) => setPadding(e.target.value)} size='xs' />
                </InfoItem>
                <InfoItem label={'Roll'}>
                    <Input value={roll} onChange={(e) => setRoll(e.target.value)} size='xs' />
                </InfoItem>
                <InfoItem label={'Pitch'}>
                    <Input value={pitch} onChange={(e) => setPitch(e.target.value)} size='xs' />
                </InfoItem>
                <InfoItem label={'Yaw'}>
                    <Input value={yaw} onChange={(e) => setYaw(e.target.value)} size='xs' />
                </InfoItem>
            </div>
        </div>
    );
}

function InfoItem({ label, children, ...props }) {
    return (
        <div class='px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0' {...props}>
            <dt class=' font-medium text-gray-900'>{label}</dt>
            <dd class='mt-1 text-gray-700 sm:col-span-2 sm:mt-0'>{children}</dd>
        </div>
    );
}
