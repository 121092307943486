import { configureStore } from '@reduxjs/toolkit';
import authReducer from 'features/auth/authSlice';
import commandReducer from 'features/command/commandSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        command: commandReducer,
    },
});
