import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { loginAPI, logoutAPI } from './authAPI';

const initialState = {
    isAuthenticated: JSON.parse(localStorage.getItem('isAuthenticated')),
    token: localStorage.getItem('token'),
    user: JSON.parse(localStorage.getItem('user')),
    loading: false,
};

export const login = createAsyncThunk('auth/login', async ({ email, password }) => {
    const response = await loginAPI({ email, password });
    return response.data;
});

export const logout = createAsyncThunk('auth/logout', async () => {
    const response = await logoutAPI();
    return response.data;
});

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.loading = true;
            })
            .addCase(login.rejected, (state) => {
                state.loading = false;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.loading = false;
                state.isAuthenticated = true;
                state.token = action.payload.access_token;
                state.user = action.payload;

                localStorage.setItem('isAuthenticated', true);
                localStorage.setItem('token', action.payload.access_token);
                localStorage.setItem('user', JSON.stringify(action.payload));
            })
            .addCase(logout.pending, (state) => {
                state.loading = true;
            })
            .addCase(logout.rejected, (state, action) => {
                console.log(action);
                state.loading = false;
            })
            .addCase(logout.fulfilled, (state) => {
                state.loading = false;
                state.isAuthenticated = false;
                state.token = null;
                state.user = {};

                localStorage.setItem('isAuthenticated', false);
                localStorage.setItem('token', null);
                localStorage.setItem('user', null);
            });
    },
});

// export const {} = authSlice.actions;
export default authSlice.reducer;
