import React from 'react';
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet';
// import { useMap } from 'react-leaflet/hooks';

function MyComponent() {
    const map = useMap();
    console.log('map center:', map.getCenter());
    return null;
}

export default function Map() {
    return (
        <div id='map-canvas' className='top-0 left-0 w-full h-full absolute'>
            <MapContainer
                className='w-full h-full relative overflow-hidden'
                // viewport={viewport}
                center={[14, 121]}
                zoom={5}
                maxZoom={20}
                minZoom={4}
                zoomControl={false}
                preferCanvas
                worldCopyJump
            >
                {/* Openstreatmaps Carto Map */}
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    url='https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png'
                />
                {/* NAMRIA basemap */}
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    url='https://basemapserver.geoportal.gov.ph/tiles/v2/PGP/{z}/{x}/{y}.png'
                />
                <Marker position={[51.505, -0.09]}>
                    <Popup>
                        A pretty CSS3 popup. <br /> Easily customizable.
                    </Popup>
                </Marker>
            </MapContainer>
        </div>
    );
}
