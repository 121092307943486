import React from 'react';

export const Button = ({ className, children, ...props }) => {
    return (
        <button
            class={`flex w-full place-content-center select-none rounded-md bg-blue-500 px-3 py-1.5 text-base font-semibold text-white shadow-sm hover:bg-dark  ${className} disabled:bg-dark`}
            {...props}
        >
            {children}
        </button>
    );
};

export const SuccessButton = ({ className, children, ...props }) => {
    return (
        <Button className={`bg-success ${className}`} {...props}>
            {children}
        </Button>
    );
};
