import Papa from 'papaparse';

export const exportData = (data, fileName, type) => {
    const csvData = Papa.unparse(data);
    // console.log('download csv');
    // Create a link and download the file
    const blob = new Blob([csvData], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
};
