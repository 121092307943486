import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    fetchSatellites,
    fetchSatellitePasses,
    fetchCaptureArea,
    fetchCommand,
    postPass,
    fetchTasks,
    postCaptureInfo,
} from './commandAPI';

import { notifications } from '@mantine/notifications';

import { exportData } from 'utils/download';

const initialState = {
    passNav: 'pass-parameter',
    taskNav: 'task-list',
    hasSatellite: false,
    selectedSatellite: null,
    satelliteList: [],
    getSatellitePassListLoading: false,
    createTaskLoading: false,
    updateTaskLoading: false,
    loading: false,
    selectingTarget: false,
    hasTarget: false,
    target: null,
    startDate: null,
    endDate: null,
    fieldOfRegard: 9.0,
    hasSatellitePassList: false,
    satellitePassList: [],
    selectedSatellitePass: null,
    hasCaptureArea: false,
    captureArea: null,
    sunshineTime: null,
    halfAngle: 1.0,
    capturePadding: 5.0,
    payload: {
        mfc_on: false,
        wfc_on: false,
        hpt_on: false,
        hpt_r_on: false,
        hpt_g_on: false,
        hpt_b_on: false,
        hpt_n_on: false,
        smi_on: false,
        smiv_on: false,
        smin_on: false,
        erc_on: false,
        lctfv_frequency: ['500', '600'],
        lctfn_frequency: ['700', '800'],
        frame_count: 20,
        frame_time: 100,
    },
    captureInfo: {},
    hasCommand: false,
    commandList: [],
    hasTaskList: false,
    taskList: [],
    selectedTaskIndex: null,
};

export const getSatellites = createAsyncThunk('command/fetchSatellites', async () => {
    const response = await fetchSatellites();
    return response.data;
});

export const getTasks = createAsyncThunk('command/fetchTasks', async () => {
    const response = await fetchTasks();
    return response.data;
});

export const getSatellitePasses = createAsyncThunk(
    'command/fetchSatellitePasses',
    async ({ satelliteId, data }) => {
        const response = await fetchSatellitePasses({ satelliteId, data });
        return response.data;
    },
);

export const getCaptureArea = createAsyncThunk(
    'command/fetchCaptureArea',
    async ({ satelliteId, data }) => {
        const response = await fetchCaptureArea({ satelliteId, data });
        return response.data;
    },
);

export const getCommand = createAsyncThunk(
    'command/fetchCommand',
    async ({ satelliteId, data }) => {
        const response = await fetchCommand({ satelliteId, data });
        return response.data;
    },
);

export const createTask = createAsyncThunk('command/postPass', async ({ data }) => {
    const response = await postPass({ data });
    return response.data;
});

export const updateCaptureInfo = createAsyncThunk(
    'command/postCaptureInfo',
    async ({ taskId, data }) => {
        const response = await postCaptureInfo({ taskId, data });
        return response.data;
    },
);

export const commandSlice = createSlice({
    name: 'command',
    initialState,
    reducers: {
        setPassNav: (state, action) => {
            state.passNav = action.payload;
        },
        setTaskNav: (state, action) => {
            state.taskNav = action.payload;
        },
        setSatellite: (state, action) => {
            state.selectedSatellite = action.payload;
        },
        enablePointSelect: (state) => {
            state.selectingTarget = true;
            state.hasSatellitePassList = false;
            state.hasTarget = false;
        },
        setTarget: (state, action) => {
            state.selectingTarget = false;
            state.hasTarget = true;
            state.target = action.payload;
        },
        setStartDate: (state, action) => {
            state.startDate = action.payload;
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload;
        },
        setFieldOfRegard: (state, action) => {
            state.fieldOfRegard = action.payload;
        },
        setHalfAngle: (state, action) => {
            state.halfAngle = action.payload;
        },
        setSelectedSatellitePass: (state, action) => {
            state.selectedSatellitePass = action.payload;
            // reset capture area
            state.hasCaptureArea = false;
        },
        setPayload: (state, action) => {
            state.payload = action.payload;
        },
        setCaptureInfo: (state, action) => {
            state.captureInfo = action.payload;
        },
        downloadCsvCommand: (state) => {
            exportData(state.commandList, 'commands.csv', 'text/csv;charset=utf-8;');
        },
        togglePassListSelected: (state, action) => {
            state.satellitePassList[action.payload.index].selected = action.payload.value;
        },
        toggleTaskListSelected: (state, action) => {
            state.taskList[action.payload.index].selected = action.payload.value;
        },
        setSelectedTaskIndex: (state, action) => {
            state.selectedTaskIndex = action.payload;
            state.taskList[action.payload].selected = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSatellites.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSatellites.rejected, (state) => {
                notifications.show({
                    title: 'Error!',
                    message: 'Failed to get Satellites!',
                    color: 'red',
                });
                state.loading = false;
            })
            .addCase(getSatellites.fulfilled, (state, action) => {
                state.loading = false;
                state.hasSatellite = true;
                state.satelliteList = action.payload;
                state.selectedSatellite = action.payload[0];
            })
            .addCase(getTasks.pending, (state) => {
                // state.loading = true;
            })
            .addCase(getTasks.rejected, (state) => {
                // alert('API Fail!');
                notifications.show({
                    title: 'Error!',
                    message: 'Failed to get Task List!',
                    color: 'red',
                });
                // state.loading = false;
            })
            .addCase(getTasks.fulfilled, (state, action) => {
                // state.loading = false;
                state.hasTaskList = true;
                state.taskList = action.payload.map((task) => {
                    return { ...task, selected: false };
                });
            })
            .addCase(getSatellitePasses.pending, (state) => {
                state.hasSatellitePassList = false;
                state.getSatellitePassListLoading = true;
            })
            .addCase(getSatellitePasses.rejected, (state, action) => {
                notifications.show({
                    title: 'Error!',
                    message: 'Failed to get Satellite Passes!',
                    color: 'red',
                });
                state.getSatellitePassListLoading = false;
            })
            .addCase(getSatellitePasses.fulfilled, (state, action) => {
                notifications.show({
                    title: 'Success!',
                    message: 'Got the passes for the target location.',
                });
                state.getSatellitePassListLoading = false;
                state.hasSatellitePassList = true;
                state.passNav = 'pass-list';
                state.satellitePassList = action.payload.passes.map((pass, i) => {
                    return { id: i + 1, selected: i === 0 ? true : false, ...pass };
                });
                state.selectedSatellitePass = state.satellitePassList[0];
            })
            // .addCase(getCaptureArea.pending, (state) => {
            //     state.hasCaptureArea = false;
            //     state.loading = true;
            // })
            // .addCase(getCaptureArea.rejected, (state, action) => {
            //     alert('API Fail!');
            //     console.log(action);
            //     state.loading = false;
            // })
            // .addCase(getCaptureArea.fulfilled, (state, action) => {
            //     state.loading = false;
            //     state.hasCaptureArea = true;
            //     state.captureArea = action.payload.capture_area_geojson;
            //     state.sunshineTime = action.payload.sunshine_time;
            // })
            // .addCase(getCommand.pending, (state) => {
            //     state.hasCommand = false;
            //     state.loading = true;
            // })
            // .addCase(getCommand.rejected, (state, action) => {
            //     alert('API Fail!');
            //     console.log(action);
            //     state.loading = false;
            // })
            // .addCase(getCommand.fulfilled, (state, action) => {
            //     state.loading = false;
            //     state.hasCommand = true;
            //     state.commandList = action.payload;
            // })
            .addCase(createTask.pending, (state) => {
                state.createTaskLoading = true;
            })
            .addCase(createTask.rejected, (state, action) => {
                notifications.show({
                    title: 'Error!',
                    message: 'Task Creation Failed!',
                    color: 'red',
                });
                state.createTaskLoading = false;
            })
            .addCase(createTask.fulfilled, (state, action) => {
                notifications.show({
                    title: 'Success!',
                    message: 'Added Pass to the Task List.',
                });
                state.createTaskLoading = false;
                state.selectedSatellitePass = null;
                state.taskList = [...state.taskList, { ...action.payload, selected: true }].sort(
                    (a, b) => b.center_time.localeCompare(a.center_time),
                );
            })
            .addCase(updateCaptureInfo.pending, (state) => {
                state.updateTaskLoading = true;
            })
            .addCase(updateCaptureInfo.rejected, (state, action) => {
                notifications.show({
                    title: 'Error!',
                    message: 'Generate Command Failed!',
                    color: 'red',
                });
                state.updateTaskLoading = false;
            })
            .addCase(updateCaptureInfo.fulfilled, (state, action) => {
                state.updateTaskLoading = false;
                const index = state.taskList.findIndex((task) => task.id === action.payload.id);
                state.taskList[index] = { ...action.payload, selected: true };
                notifications.show({
                    title: 'Success!',
                    message: 'Updated Task.',
                });
            });
    },
});

export const {
    setSatellite,
    enablePointSelect,
    setTarget,
    setStartDate,
    setEndDate,
    setFieldOfRegard,
    setHalfAngle,
    setSelectedSatellitePass,
    setPayload,
    setCaptureInfo,
    downloadCsvCommand,
    setPassNav,
    setTaskNav,
    togglePassListSelected,
    toggleTaskListSelected,
    setSelectedTaskIndex,
} = commandSlice.actions;
export default commandSlice.reducer;
