import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { updateCaptureInfo } from 'features/command/commandSlice';

import CaptureOpportunityInfo from 'components/command/BottomCompartment/TaskDetailSection/CaptureOpportunityInfo';
import PayloadOptions from 'components/command/BottomCompartment/TaskDetailSection/PayloadOptions';
import CommandSection from 'components/command/BottomCompartment/TaskDetailSection/CommandSection';

import { SuccessButton } from 'components/common/Button';

import { isValidFloat, isValidInteger, isListAllInteger } from 'utils/utils';

import { notifications } from '@mantine/notifications';

import { LoadingOverlay } from '@mantine/core';

export default function TaskDetailSection() {
    const dispatch = useDispatch();

    const updateTaskLoading = useSelector((state) => state.command.updateTaskLoading);

    const captureInfo = useSelector((state) => state.command.captureInfo);
    const payload = useSelector((state) => state.command.payload);

    const taskList = useSelector((state) => state.command.taskList);
    const selectedTaskIndex = useSelector((state) => state.command.selectedTaskIndex);

    const selectedTask = taskList[selectedTaskIndex];

    // if (loading) return <div>loading...</div>;

    const onSubmit = () => {
        // validate capture info

        let hasError = false;
        if (!isValidFloat(captureInfo.capture_angle)) {
            notifications.show({
                title: 'Warning!',
                message: 'Please provide a valid Angle.',
                color: 'orange',
            });
            hasError = true;
        }
        if (!isValidInteger(captureInfo.padding) || parseInt(captureInfo.padding) <= 0) {
            notifications.show({
                title: 'Warning!',
                message: 'Please provide a valid Padding.',
                color: 'orange',
            });
            hasError = true;
        }
        if (
            !isValidFloat(captureInfo.roll) ||
            parseFloat(captureInfo.roll) > 180 ||
            parseFloat(captureInfo.roll) < -180
        ) {
            notifications.show({
                title: 'Warning!',
                message: 'Please provide a valid Roll.',
                color: 'orange',
            });
            hasError = true;
        }
        if (
            !isValidFloat(captureInfo.pitch) ||
            parseFloat(parseFloat(captureInfo.pitch)) > 180 ||
            parseFloat(parseFloat(captureInfo.pitch)) < -180
        ) {
            notifications.show({
                title: 'Warning!',
                message: 'Please provide a valid Pitch.',
                color: 'orange',
            });
            hasError = true;
        }
        if (
            !isValidFloat(captureInfo.yaw) ||
            parseFloat(captureInfo.yaw) > 180 ||
            parseFloat(captureInfo.yaw) < -180
        ) {
            notifications.show({
                title: 'Warning!',
                message: 'Please provide a valid Yaw.',
                color: 'orange',
            });
            hasError = true;
        }

        // validate payload
        const totalPayload = [
            payload.mfc_on,
            payload.wfc_on,
            payload.hpt_r_on,
            payload.hpt_g_on,
            payload.hpt_b_on,
            payload.hpt_n_on,
            payload.smi_v_on,
            payload.smi_n_on,
        ].filter((value) => value).length;

        if (totalPayload === 0) {
            notifications.show({
                title: 'Warning!',
                message: 'Please select a Payload.',
                color: 'orange',
            });
            hasError = true;
        }
        if (totalPayload > 6) {
            notifications.show({
                title: 'Warning!',
                message: 'Please do not select more than 6 Payloads.',
                color: 'orange',
            });
            hasError = true;
        }

        if (!isListAllInteger(payload.lctfv_frequency.split(' '))) {
            notifications.show({
                title: 'Warning!',
                message: 'Provide a valid list of Visible SMI frequencies.',
                color: 'orange',
            });
            hasError = true;
        }
        if (!isListAllInteger(payload.lctfn_frequency.split(' '))) {
            notifications.show({
                title: 'Warning!',
                message: 'Provide a valid list of NIR SMI frequencies.',
                color: 'orange',
            });
            hasError = true;
        }

        if (hasError) return;

        const data = {
            payload: {
                ...payload,
                lctfv_frequency: payload.lctfv_frequency
                    .split(' ')
                    .filter((a) => a !== '')
                    .map((f) => parseInt(f)),
                lctfn_frequency: payload.lctfn_frequency
                    .split(' ')
                    .filter((a) => a !== '')
                    .map((f) => parseInt(f)),
            },
            // ...captureInfo,
            caption: captureInfo.caption,
            center_time: captureInfo.center_time,
            capture_angle: parseFloat(captureInfo.capture_angle),
            half_angle: parseFloat(captureInfo.half_angle),
            padding: parseInt(captureInfo.padding),
            roll: parseFloat(captureInfo.roll),
            pitch: parseFloat(captureInfo.pitch),
            yaw: parseFloat(captureInfo.yaw),
        };
        // console.log(data);

        dispatch(
            updateCaptureInfo({
                taskId: selectedTask.id,
                data,
            }),
        );
    };

    return (
        <div class='flex flex-col h-132'>
            <LoadingOverlay visible={updateTaskLoading} overlayBlur={2} zIndex={5} />
            <div class='flex flex-wrap'>
                <div class='w-full md:w-1/2 lg:w-1/3 py-4 px-2'>
                    <CaptureOpportunityInfo />
                </div>
                <div class='w-full md:w-1/2 lg:w-1/3 py-4 px-2'>
                    <PayloadOptions />
                </div>
                <div class='w-full md:w-2/2 lg:w-1/3 py-4 px-2'>
                    <CommandSection />
                </div>
            </div>
            <div class='pt-5'>
                <SuccessButton onClick={onSubmit}>Generate Command</SuccessButton>
            </div>
        </div>
    );
}
