import React from 'react';

export default function Header({ iconUrl }) {
    return (
        <div class='sm:mx-auto sm:w-full sm:max-w-sm'>
            <img class='mx-auto h-10 w-auto' src={iconUrl} alt='Your Company' />
            <h2 class='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
                Sign in to your account
            </h2>
        </div>
    );
}
